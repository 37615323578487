
  import React from 'react';
  import COMPONENTS from '../../components';
  import { get } from 'lodash';
  import PageLayout from '../../layout';
  import { graphql } from 'gatsby';

  class Page extends React.Component {
    state = {
      data: [],
    };

    componentDidMount() {
      let slices;

      const data = get(this, 'props.data.corporatesIndustriesJson');

      if (data) {
        slices = Object.entries(data).map(([key, content]) => ({
          componentName: key,
          ...content,
        }));

        slices.sort(
          ({ position: positionA }, { position: positionB }) =>
            positionA - positionB
        );

        this.setState({
          data: slices,
        });
      }
    }

    render() {
      return (
        <PageLayout>
          {get(this, 'state.data').map(({ componentName, data }, index) => {
            const ComponentType = COMPONENTS[componentName];
            return <ComponentType key={index} {...data} />;
          })}
        </PageLayout>
      );
    }
  }

  export default Page;

  export const pageQuery = graphql`
    query {
      corporatesIndustriesJson {
        
      
      
      
      
      
      
      
      CTAColumn0 {
        position
        componentName
        data {
           title type description button {  text }
        }
      }
    
      ProductSingle1 {
        position
        componentName
        data {
           title textBody image background imageSide
        }
      }
    
      Feature3Column2 {
        position
        componentName
        data {
           features {  logo title textBody }
        }
      }
    
      CarouselTestimonials3 {
        position
        componentName
        data {
           quotes {  quote picture subtitle }
        }
      }
    
      CTA4 {
        position
        componentName
        data {
           title button {  text }
        }
      }
    
      TextGrid5 {
        position
        componentName
        data {
           header sections {  title textBody }
        }
      }
    
      Article3Column6 {
        position
        componentName
        data {
           header articles {  link image date textBody }
        }
      }
    
      }
    }
  `